import './bootstrap';
import $ from 'jquery';
window.jQuery = window.$ = $

import { Livewire, Alpine } from '../../../../../vendor/livewire/livewire/dist/livewire.esm';

window.Alpine = Alpine

import './libs/perfect-scrollbar/perfect-scrollbar';

import './config';
import './template-customizer';
import './menu';
import './dropdown-hover';
//import helpers from './helpers';
//window.Helpers = helpers;

//import TemplateCustomizer from "./template-customizer";
//window.templateCustomizer = TemplateCustomizer;

//import './mega-dropdown';
import '@nextapps-be/livewire-sortablejs';

import './main';

//import './template-customizer';
import './libs/datatables-bs5/datatables-bootstrap5';

import './libs/flatpickr/flatpickr';

flatpickr.l10ns.default.firstDayOfWeek = 1;
flatpickr.l10ns.de.firstDayOfWeek = 1;

import { German } from 'flatpickr/dist/l10n/de';
window.FlatpickrGerman = German;

import Swal from 'sweetalert2'
window.Swal = Swal;

//import {Quill} from './libs/quill/quill';
//window.Quill = Quill;

// ------------------- SELECT 2 ------------------------------ //

import select2 from 'select2';

/*! Select2 4.1.0-rc.0 | https://github.com/select2/select2/blob/master/LICENSE.md */


select2($);
!function(){if(jQuery&&jQuery.fn&&jQuery.fn.select2&&jQuery.fn.select2.amd)var e=jQuery.fn.select2.amd;e.define("select2/i18n/de",[],function(){return{errorLoading:function(){return"Die Ergebnisse konnten nicht geladen werden."},inputTooLong:function(e){return"Bitte "+(e.input.length-e.maximum)+" Zeichen weniger eingeben"},inputTooShort:function(e){return"Bitte "+(e.minimum-e.input.length)+" Zeichen mehr eingeben"},loadingMore:function(){return"Lade mehr Ergebnisse…"},maximumSelected:function(e){var n="Sie können nur "+e.maximum+" Element";return 1!=e.maximum&&(n+="e"),n+=" auswählen"},noResults:function(){return"Keine Übereinstimmungen gefunden"},searching:function(){return"Suche…"},removeAllItems:function(){return"Entferne alle Elemente"},removeItem:function(){return"Element entfernen"},search:function(){return"Suchen"}}}),e.define,e.require}();

$(function () {
    const selectPicker = $('.selectpicker'),
        select2 = $('.select2'),
        select2_single_tag = $('.select2-single-tag'),
        select2Icons = $('.select2-icons'),
        select2_tags = $('.select2-tags');


    // Bootstrap Select
    // --------------------------------------------------------------------
    if (selectPicker.length) {
        selectPicker.selectpicker();
    }

    // Select2
    // --------------------------------------------------------------------

    // Default
    if (select2.length) {
        select2.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>').select2({
                language: 'de',
                placeholder: 'Select value',
                dropdownParent: $this.parent()
            });
        });
    }
    if (select2_single_tag.length) {
        select2_single_tag.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>').select2({
                language: 'de',
                placeholder: 'Select value',
                tags: true,
                dropdownParent: $this.parent()
            });
        });
    }

    if (select2_tags.length) {
        select2_tags.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>').select2({
                language: 'de',
                tags: true,
            });
        });
    }


    // Select2 Icons
    if (select2Icons.length) {
        // custom template to render icons
        function renderIcons(option) {
            if (!option.id) {
                return option.text;
            }
            var $icon = "<i class='" + $(option.element).data('icon') + " me-2'></i>" + option.text;

            return $icon;
        }
        select2Icons.wrap('<div class="position-relative"></div>').select2({
            templateResult: renderIcons,
            templateSelection: renderIcons,
            escapeMarkup: function (es) {
                return es;
            }
        });
    }
});

// ------------------- / SELECT 2 ------------------------------ //



import DualListbox from "dual-listbox/src/dual-listbox";
window.DualListbox = DualListbox;
/*
$('.menu-toggle').on('click', function(e) {
    e.preventDefault();
    if($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).parent().find('.menu-sub').slideUp();
    }else {
        $(this).addClass('active');
        $(this).parent().find('.menu-sub').slideDown();
    }
});
*/
import mask from '@alpinejs/mask'

Alpine.plugin(mask)

Livewire.start()

//settimeout
$(document).ready(function(){
    setTimeout(function(){
        $('.dataTables_processing').fadeOut('fast');
    }, 500);
    console.log('READY FOR E V E R Y T H I N G.')
});
